import React, { useEffect, useState } from "react";
import { Input, InputGroup } from "reactstrap";
import { default as AvFieldSelect } from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Common/Loader";
import { getAccountsStart } from "store/actions";
import { getConversionRate } from "apis/conversionRate";

export default function AsiaPayMt5Details(props) {
  const { 
    t,
    amount,
    setAmount,
    amountError,
    setTargetCurrency: setCurrency,
    amountValidation,
    usdAmount,
    setUsdAmount
  } = props;
  const dispatch = useDispatch();
  const { accounts, loading } = useSelector((state) => state.forex.accounts);
  const { clientData } = useSelector(state => state.Profile);
  const [conversionRate, setConversionRate] = useState(0);
  const [targetCurrency, setTargetCurrency] = useState("USD");
  const [baseCurrency, setBaseCurrency] = useState("USD");

  useEffect(() => {
    dispatch(
      getAccountsStart({})
    );
    let targetCurrency = "USD";
    switch (clientData.country) {
      case "Malaysia":
        targetCurrency = "MYR";
        break;
      case "India":
        targetCurrency = "INR";
        break;
      case "Indonesia":
        targetCurrency = "IDR";
        break;
      case "Vietnam":
        targetCurrency = "VND";
        break;
      default:
        break;
    }
    getConversionRate({
      baseCurrency: "USD",
      targetCurrency: targetCurrency,
    }).then((res) => {
      setConversionRate(res);
    });
    setTargetCurrency(targetCurrency);
    setCurrency(targetCurrency);
  }, []);

  return (
    <div className="w-100">
      {loading ? <Loader /> : accounts && (
        <>
          <InputGroup>
            <label className="w-100">{t(`Amount in ${targetCurrency}`)}</label>
            <Input
              required
              onChange={(e) => {
                setAmount(e.target.value);
                setUsdAmount((parseFloat(e.target.value) / parseFloat(conversionRate)).toFixed(3));
              }}
              className="form-control"
              type="number"
              min="0"
              value={amount}
              placeholder={`Enter 15-128341 ${targetCurrency}`}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </InputGroup>
          <div className="mt-3">
            <AvFieldSelect
              options={
                accounts?.filter(a => !["DEMO", "MAM_FEE", "IB"].includes(a.type))?.filter(a => a.profile ? a.profile?.profileType === 9 : true).map(acc=>{
                  return {
                    label:`${acc.login} ${acc?.accountTypeId?.title || "-"} (${acc.Balance} ${acc.currency})`,
                    value: acc._id
                  };
                })
              }
              name="tradingAccountId"
              type="text"
              errorMessage={t("MT5 account is required")}
              validate={{ required: { value: true } }}
              label={t("MT5 Account")}
            >
            </AvFieldSelect>
          </div>
        </>
      )}
      
      {conversionRate !== 0 && amount > 0 && (
        <div className="text-center mt-4">
          <span className="fs-5">{t("Conversion Rate: " + amount  + " " + targetCurrency + " = "  + `${usdAmount}` + ` ${baseCurrency}`   )}</span>
        </div>
      )}
      {console.log("amountValidation: ", amountValidation)}
      {amountValidation && (
        <p className="small text-danger d-flex align-items-center justify-content-center"> {t("The minimum value for amount is 15 USD")}</p>
      )}
    </div>
  );
}
