import CustomSelect from "components/Common/CustomSelect";
import Loader from "components/Common/Loader";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets } from "store/actions";
import {
  Input,
  InputGroup,
  InputGroupText,
  Label
} from "reactstrap";
import { getConversionRate } from "apis/conversionRate";


export default function AsiaPayWalletDetails(props) {
  const {
    t,
    selectedWallet,
    amount,
    setAmount,
    setSelectedWallet,
    setAmountValidation,
    setTargetCurrency: setCurrency,
    amountValidation,
    usdAmount,
    setUsdAmount
  } = props;
  const dispatch = useDispatch();
  const [amountError, setAmountError] = useState(false);
  const [minAmount, setMinAmount] = useState(1);
  const { wallets, loading } = useSelector((state) => state?.walletReducer);
  const { clientData } = useSelector(state => state.Profile);
  const [targetCurrency, setTargetCurrency] = useState("USD");
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const [conversionRate, setConversionRate] = useState(0);

  useEffect(() => {
    if (amount < minAmount) {
      setAmountError(true);
      setAmountValidation(true);
    } else {
      setAmountError(false);
      setAmountValidation(false);
    }
  }, [amount, minAmount, selectedWallet]);

  useEffect(() => {
    if (wallets?.length > 0) {
      setSelectedWallet({
        label: wallets[0].asset,
        value: wallets[0]._id,
        image: getAssetImgSrc(wallets[0].assetId)
      });
    }
  }, [wallets]);

  const loadWallets = () => {
    dispatch(fetchWallets());
  };

  useEffect(() => {
    if (!wallets || wallets?.length === 0) {
      loadWallets({
        isCrypto: false,
        isInventory: false,
      });
    }
  }, []);

  const fiatWallets = wallets?.map((wallet) => {
    return {
      label: wallet.asset,
      value: wallet._id,
      image: getAssetImgSrc(wallet.assetId)
    };
  });

  useEffect(()=>{
    let targetCurrency = "USD";
    switch (clientData.country) {
      case "Malaysia":
        targetCurrency = "MYR";
        break;
      case "India":
        targetCurrency = "INR";
        break;
      case "Indonesia":
        targetCurrency = "IDR";
        break;
      case "Vietnam":
        targetCurrency = "VND";
        break;
      default:
        break;
    }
    getConversionRate({
      baseCurrency: "USD",
      targetCurrency: targetCurrency,
    }).then((res) => {
      setConversionRate(res);
    });
    setTargetCurrency(targetCurrency);
    setCurrency(targetCurrency);
  }, []);

  return (
    <div className="w-100">
      {loading ? <Loader /> : (
        <>
          {t("Transaction requirements")}
          <i className="fas fa-info-circle ms-2"></i>
          <div className="mt-3">
            <Label for="amount">{t(`Amount in ${targetCurrency}`)}</Label>
            <InputGroup>
              <Input
                name="amount"
                required
                onChange={(e) => {
                  setAmount(e.target.value);
                  setUsdAmount((parseFloat(e.target.value) / parseFloat(conversionRate)).toFixed(3));
                }}
                className="form-control"
                type="number"
                min="0"
                value={amount}
                placeholder={`Enter ${minAmount}-128341 ${targetCurrency}`}
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <InputGroupText className="custom-input-group-text p-0 border-0">
                <div style={{
                  width: "127px",
                  height: "100%"
                }}
                >
                  <CustomSelect
                    placeholder="Select"
                    name="wallets"
                    isRequired
                    options={fiatWallets}
                    defaultValue={fiatWallets[0]}
                    onChange={(e) => {
                      setSelectedWallet(e);
                    }}
                  >
                  </CustomSelect>
                </div>
              </InputGroupText>
            </InputGroup>
          </div>
          {/* {amountError && (
            <p className="small text-danger "> {
              !amount ? t("Amount is required") : t("Amount must be greater than " + minAmount)
            } </p>
          )} */}
          { conversionRate !== 0 && amount > 0 && (
            <div className="text-center mt-4">
              <span className="fs-5">{t("Conversion Rate: " + amount  + " " + targetCurrency + " = "  + `${usdAmount}` + ` ${baseCurrency}`   )}</span>
            </div>
          )}
          {amountValidation && (
            <p className="small text-danger d-flex align-items-center justify-content-center"> {t("The minimum value for amount is 15 USD")}</p>
          )}
        </>
      )}
    </div>
  );
}
