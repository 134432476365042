import React, { useEffect } from "react";

export default function AsiaBanks(props) {
  const { setIsFirstStepValid } = props;

  useEffect(()=>{
    setIsFirstStepValid(true);
  }, []);  
  return (
    <></>
  );
}
