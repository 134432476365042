import React, { useEffect, useRef } from "react";

const APForm = (props) => {
  const data = props.result.result;
  const formRef = useRef(null);
  const {
    merchant_reference,
    currency,
    amount,
    customer_ip,
    customer_first_name,
    customer_last_name,
    customer_email,
    customer_phone,
    return_url,
    network,
    sign,
    merchantToken,
  } = data;

  useEffect(() => {
    formRef.current.submit();
  }, []);

  return (
    <div>
      <form
        ref={formRef}
        action={`https://payment.pa-sys.com/app/page/${merchantToken}`}
        method="post"
        target="_top"
      >
        <input type="hidden" name="merchant_reference" value={merchant_reference} />
        <input type="hidden" name="currency" value={currency} />
        <input type="hidden" name="amount" value={amount} />
        <input type="hidden" name="customer_ip" value={customer_ip} />
        <input type="hidden" name="customer_first_name" value={customer_first_name} />
        <input type="hidden" name="customer_last_name" value={customer_last_name} />
        <input type="hidden" name="customer_email" value={customer_email} />
        <input type="hidden" name="customer_phone" value={customer_phone} />
        <input type="hidden" name="return_url" value={return_url} />
        <input type="hidden" name="network" value={network} />
        <input type="hidden" name="sign" value={sign} />
      </form>
    </div>
  );
};

export default APForm;