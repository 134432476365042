import config from "config";
import AsiaBanks from "assets/img/payment-method/asiabanks.png";
import Epayme from "assets/img/payment-method/epayme.png";
import usdt from "assets/images/payment-method/USDT.png";
import { INT_COUNTRIES } from "helpers/countries";

export const allowedMethods = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/wire-transfer.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
    showForCountries: [...INT_COUNTRIES]
  },
  {
    gateway: "LOCAL_WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/bank_local.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
    showForCountries: ["India"]
  },
  {
    gateway: "ASIA_BANKS",
    name: "Asia Banks",
    image: AsiaBanks,
    allowed: ["mt5Deposit", "fiatDeposit"],
    receipt: false,
    showForCountries: ["Malaysia", "India", "Indonesia", "Vietnam"]
  },
  {
    gateway: "EPAYME",
    name: "epayme",
    image: Epayme,
    allowed: ["mt5Deposit", "fiatDeposit"],
    receipt: false,
  },
  // {
  //   gateway: "OLX_FOREX",
  //   name: "Olx Forex",
  //   image: "/img/payment-method/olxforex.jpeg",
  //   allowed: ["fiatDeposit"],
  //   receipt: false,
  // },
  {
    gateway: "CRYPTO",
    name: "Crypto",
    image: "/img/payment-method/crypto.png",
    allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit"] : [],
    receipt: false,
  },
  {
    gateway: "GPAY",
    name: "Gpay",
    image: "/img/payment-method/Gpay.JPG",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
  },
  {
    gateway: "FINITIC_PAY",
    name: "Finitic Pay",
    image: usdt,
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: false,
    // isSandbox: true,
  },
];