import React, { useEffect } from "react";
import { Input, InputGroup } from "reactstrap";
import { default as AvFieldSelect } from "components/Common/AvFieldSelect";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Common/Loader";
import { getAccountsStart } from "store/actions";
import AsiaPayMt5Details from "./AsiaPayMt5Details";

export default function Mt5Details(props) {
  const { 
    t,
    amount,
    setAmount,
    amountValidation,
    amountError,
    conversionRate,
    baseCurrency,
    targetCurrency,
    setTargetCurrency,
    gateway
  } = props;
  const dispatch = useDispatch();
  const { accounts, loading } = useSelector((state) => state.forex.accounts);

  useEffect(() => {
    dispatch(
      getAccountsStart({})
    );
  }, []);

  return (
    <div className="w-100"> 
      {gateway === "ASIA_BANKS" ? <>
        <AsiaPayMt5Details {...props}></AsiaPayMt5Details>
      </> : <>     
        {loading ? <Loader /> : accounts && (
          <>
            <InputGroup>
              <label className="w-100">{t("Amount in USD")}</label>
              <Input
                required
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                className="form-control"
                type="number"
                min="0"
                value={amount}
                placeholder="Enter 15-128341 USD"
                onKeyPress={(e) => {
                  if (!/[0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </InputGroup>
            {amountValidation && (
              <p className="small text-danger "> {t("The minimum value for amount is 15 USD")}</p>
            )}
            <div className="mt-3">
              <AvFieldSelect
                options={
                  accounts?.filter(a => !["DEMO", "MAM_FEE", "IB"].includes(a.type))?.filter(a => a.profile ? a.profile?.profileType === 9 : true).map(acc=>{
                    return {
                      label:`${acc.login} ${acc?.accountTypeId?.title || "-"} (${acc.Balance} ${acc.currency})`,
                      value: acc._id
                    };
                  })
                }
                name="tradingAccountId"
                type="text"
                errorMessage={t("MT5 account is required")}
                validate={{ required: { value: true } }}
                label={t("MT5 Account")}
              >
              </AvFieldSelect>
            </div>
            {amountError && (
              <p className="small text-danger "> {t("Please Select Account")}</p>
            )}
          </>
        )}
      
        {
          conversionRate !== 0 && amount > 0 && (
            <div className="text-center mt-4">
              <span className="fs-5">{t("Conversion Rate: " + amount  + " USD" + " = "  + `${parseFloat(conversionRate?.toFixed(3) * amount)?.toFixed(3)}` + ` ${baseCurrency}`   )}</span>
            </div>
          )
        }
      </>}
    </div>
  );
}
